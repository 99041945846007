<template>
  <b-container fluid v-if="profile">
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col lg="12" class="profile-center">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Información personal</h4>
              </template>
              <template v-slot:headerAction>
                <b-button variant="primary" :to="{ name: 'profile.edit' }"
                  >Editar Perfil</b-button
                >
              </template>
              <template v-slot:body>
                <b-row align-v="center">
                  <b-form-group class="col-md-3">
                    <h6 class="custom-text"><strong>NOMBRES: </strong></h6>
                    <h5>{{ profile.firstname }}</h5>
                  </b-form-group>
                  <b-form-group class="col-md-3">
                    <h6 class="custom-text"><strong>APELLIDOS: </strong></h6>
                    <h5>{{ profile.lastname }}</h5>
                  </b-form-group>
                  <b-form-group class="col-md-3">
                    <h6 class="custom-text"><strong>RUT: </strong></h6>
                    <h5>{{ profile.rut }}</h5>
                  </b-form-group>
                  <b-form-group class="col-sm-3">
                    <h6 class="custom-text"><strong>TELEFÓNO: </strong></h6>
                    <h5>
                      {{ profile.phone ? profile.phone : "Sin teléfono" }}
                    </h5>
                  </b-form-group>
                  <b-form-group class="col-sm-3">
                    <h6 class="custom-text"><strong>CORREO ELECTRÓNICO: </strong></h6>
                    <h5>{{ profile.email }}</h5>
                  </b-form-group>
                  <b-form-group class="col-md-3">
                    <h6 class="custom-text"><strong>COMUNA: </strong></h6>
                    <h5>{{ profile.comuna ? profile.comuna.name : "" }}</h5>
                  </b-form-group>
                  <b-form-group class="col-sm-6">
                    <h6 class="custom-text"><strong>DIRECCION: </strong></h6>
                    <h5>{{ profile.address }}</h5>
                  </b-form-group>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'
export default {
  name: 'Profile',
  mounted () {
    core.index()
  },
  async created () {
    this.$store.commit('run')
    const profile = await this.$store.dispatch('profile/fetchUserProfile')
    this.$store.commit('stop')
    this.profile = profile
  },
  data () {
    return {
      profile: null
    }
  },
  computed: {
    ...mapGetters({
      checkAuth: 'auth/check',
      userAuth: 'auth/user'
    })
  }
}
</script>
<style>
.custom-text {
  font-size: 0.7em !important;
  color: #a2a0a0 !important;
}
</style>
